import Behaviour from './behaviour'
import {log} from '../debug.js.erb'

require('select2/dist/css/select2.css')

class Select extends Behaviour {
  init() {
    log('Select', 'Init Select behaviour')

    const options = {
      // minimumResultsForSearch: Infinity
      templateResult: item => item.text || '\u200B',
      width: '100%',
      'language': {
        'noResults'() {
            return 'Nessun risultato'
          }
      },

      // Optimized sorting results http://stackoverflow.com/questions/
      // 31991217/select2-sorting-results-by-query
      sorter(results) {
        const query = $('.select2-search__field').val().toLowerCase()
        return results.sort((a, b) => (
          a.text.toLowerCase().indexOf(query) -
          b.text.toLowerCase().indexOf(query)
        ))
      }
    }

    if (this.$dom.attr('multiple')) {
      options.multiple = true
    }

    if (this.data('placeholder')) {
      options.placeholder = this.data('placeholder')
    }
    if (this.$dom.data('maxlength')) {
      options.maximumSelectionLength = this.$dom.data('maxlength')
    }

    return this.$dom.select2(options)
  }
}

export default Select
