import Behaviour from './behaviour'
import {log} from '../debug.js.erb'

class SetLocation extends Behaviour {
  init() {
    log('SetLocation', 'SetLocation.init() called')

    return this.$dom.change(() => {
      log('SetLocation', 'SetLocation change!')
      const clientId = this.$dom.val()

      return $.get(`/clients/${clientId}/locations/for_select`)
        .then(response => response)
    })
  }
}

export default SetLocation
