import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class Uploader extends Behaviour {
  init() {
    debug('Init Uploader behaviour')

    const outerContainer = this.$dom.closest('[role="nested-field"]')

    this.addButton = this.$dom.find('[role="add-attachment"]')
    this.uploader = this.$dom.find('input[type="file"]')
    this.removeButton = outerContainer.find('[role="remove-attachment"]')

    this.filename = this.$dom.find('input.hidden_value')
    this.path = outerContainer.find('[role="path-field"]')
    this.s3_public_url = outerContainer.find('[role="public-url-field"]')
    this.kind = outerContainer.find('[role="kind-field"]')

    this.upload_filename = outerContainer.find('[role="upload-filename"]')
    this.preview_image = outerContainer.find('[role="preview-image"]')
    this.download_link = outerContainer.find('[role="download-link"]')
    this.percentage = outerContainer.find('[role="percentage"]')
    this.spinner = outerContainer.find('[role="spinner"]')

    if (!this.filename.val()) {
      this.removeButton.hide()
      this.preview_image.hide()
    }

    this.addButton.on('click', () => {
      return this.uploader.trigger('click')
    })

    return this.uploader.on('change', e => {
      const file = e.target.files[0]
      return this.uploadFile(file)
    })
  }

  uploadFile(file) {
    return this.requestUpload(file).then(response => {
      this.$dom.css('opacity', '0.3')
      this.percentage.show()
      return this.sendFile(file, response)
    }).then(data => {
      if (data.kind === 'image') {
        this.spinner.show()
        this.preview_image.attr('src', data.asset_url)
        this.preview_image.closest('a').attr('href', data.s3_public_url)
        this.preview_image.parent().show()
        this.preview_image.show()
      } else {
        this.download_link.html(data.filename)
        this.download_link.attr('href', data.s3_public_url)
        this.download_link.show()
        this.preview_image.hide()
        this.preview_image.parent().hide()
        this.upload_filename.html(data.filename)
      }
      this.percentage.hide()
      this.s3_public_url.val(data.s3_public_url)
      this.kind.val(data.kind)
      this.filename.val(data.filename)
      this.path.val(data.path)
      this.$dom.css('opacity', '1')
      this.preview_image.on('load', () => this.spinner.hide())
      return debug('Finished upload')
    })
  }

  requestUpload(file) {
    const data = JSON.stringify({data: {filename: file.name}})

    return $.ajax({
      type: 'post',
      url: '/api/v1/upload_request',
      data,
      dataType: 'json',
      contentType: 'application/json'
    })
  }

  sendFile(file, response) {
    const deferred = $.Deferred()
    const xhr = new XMLHttpRequest()

    xhr.onreadystatechange = function() {
      if (this.readyState === 4) {
        if (this.status === 200) {
          return deferred.resolve(response)
        } else {
          return deferred.reject(this)
        }
      }
    }

    xhr.upload.onprogress = e => {
      const done = e.loaded || e.position
      const total = e.total || e.totalSize
      const progress = Math.round((done / total)*100)
      this.percentage.text(`${progress}%`)
      return debug(`Upload progress: ${progress}%`)
    }

    xhr.open('PUT', response.s3_url)
    xhr.setRequestHeader('x-amz-acl', 'public-read')
    xhr.send(file)
    return deferred.promise()
  }
}

export default Uploader
