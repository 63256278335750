/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https:/github.com/decaffeinate/decaffeinate/blob/master/
              docs/suggestions.md
 */

import Behaviour from './behaviour'
import debug from '../debug.js.erb'

import * as SignaturePad from '../../vendor/signature_pad'

class Signature extends Behaviour {

  init() {
    debug('Init Signature behaviour')
    this.post_url = this.data('post-url')
    this.responsible = this.data('responsible') || false
    this.canvas = this.$dom.find('canvas')[0]
    this.buttons_disabled = true
    this.form_error = this.$dom.find('.form__error')

    this.loadPlugin()

    this.$dom.find('input[name="rating"]').on('change', e => {
      this.buttons_disabled = false
    })

    this.clear_button = this.$dom.find('[data-role="clear"]')
    this.clear_button.on('click', e => {
      if (!this.buttons_disabled) {
        this.handleClear()
      }
      return e.preventDefault()
    })

    this.save_button = this.$dom.find('[data-role="save"]')
    this.save_button.on('click', e => {
      e.preventDefault()

      if (!this.currentRating() && this.responsible) {
        this.form_error.toggle("hidden")
        return
      }

      if (this.buttons_disabled && this.responsible) {
        return
      }

      this.disableButtons(e.currentTarget)
      this.postSignature()
    })

    this.cancel_button = this.$dom.find('[data-role="cancel"]')
    this.cancel_button.on('click', e => {
      e.preventDefault()
      // if call to server is in progress but something went wrong,
      // better reload the page
      if (this.buttons_disabled) {
        return location.reload()
      }
    })

    // Handling high DPI screens trick
    window.addEventListener('resize', this.resizeCanvas)
    return this.resizeCanvas()
  }

  currentRating() {
    return $('input[name="rating"]:checked').val()
  }

  loadPlugin() {
    return this.signaturePad = new SignaturePad(this.canvas)
  }

  handleClear() {
    return this.signaturePad.clear()
  }

  disableButtons(_elem) {
    // $(elem).html('Upload in corso...')
    this.buttons_disabled = true
    this.save_button.addClass('button--disabled')
    return this.clear_button.addClass('button--disabled')
  }

  postSignature() {
    const dataURL = this.canvas.toDataURL('image/png')
    // window.open(dataURL)
    return $.ajax({
      type: 'POST',
      url: this.post_url,
      data: {
        signature_data: dataURL,
        rating: this.currentRating()
      },
      dataType: 'script'
    })
  }

  resizeCanvas() {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1)
    this.canvas.width = this.canvas.offsetWidth * ratio
    this.canvas.height = this.canvas.offsetHeight * ratio
    this.canvas.getContext('2d').scale(ratio, ratio)
    return this.signaturePad.clear()
  }
}

export default Signature
