import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class CheckboxHidesElement extends Behaviour {
  init() {
    debug('Init CheckboxHidesElement behaviour')

    const hideable = this.data('hideable')

    this.execute(this.$dom, hideable)
    return this.$dom.click(e => {
      return this.execute(e.currentTarget, hideable)
    })
  }

  execute(target, hideable) {
    const hideable_element = $(document).find(`${hideable}`)

    if ($(target).prop('checked')) {
      return hideable_element.prop('disabled', false)

    } else {
      hideable_element.prop('disabled', true)
      return hideable_element.prop('checked', false)
    }
  }
}

export default CheckboxHidesElement
