import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class SearchToggle extends Behaviour {
  init() {
    debug('Init SearchToggle behaviour')

    return this.$dom.click(e => {
      e.preventDefault()

      $('.search__box').toggleClass('hidden')
      return this.$dom.toggleClass('is-active')
    })
  }
}

export default SearchToggle
