import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class DownloadToggle extends Behaviour {
  init() {
    debug('Init Download behaviour')

    return this.$dom.click(e => {
      e.preventDefault()

      $('.download__box').toggleClass('hidden')
      return this.$dom.toggleClass('is-active')
    })
  }
}

export default DownloadToggle
