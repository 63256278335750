import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class FlashClose extends Behaviour {
  init() {
    debug('Init FlashClose behaviour')

    this.flash = this.$dom
    this.button = this.flash.find('.flash__item__close')

    this.button.click(e => {
      e.preventDefault()

      return this.flash.addClass('is-hidden')
    })

    return setTimeout((() => this.flash.addClass('is-hidden') ), 4000)
  }
}

export default FlashClose
