import Behaviour from './behaviour'
import debug from '../debug.js.erb'

const token = process.env.DATOCMS_API_KEY
const maxItem = 3
const changelogsUrl = 'http://gestart.net/novita'

const fetchData = async () => {
  const response = await fetch(
    'https://graphql.datocms.com/',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        query: `
          query {
            allChangelogs(first: ${maxItem}) {
              _publishedAt
              title
              abstract
              tags {
                name
                colour {
                  hex
                }
              }
            }
          }
        `
      }),
    }
  )
  const data = await response.json()

  return data
}

class ChangelogToggle extends Behaviour {
  init() {
    debug('Init ChangelogToggle behaviour')

    const target = this.data('target')
    const targetElement = $(target)
    const targetClose = this.data('close_element')
    const targetCloseElement = $(targetClose)
    const element = this.data('element')

    this.$dom.ready(_e => {
      if (element) {
        this.appendChengelogs(targetElement, element)
      }
    })

    this.$dom.click(e => {
      e.preventDefault()

      targetElement.toggleClass('is-active')
    })

    targetCloseElement.click(e => {
      e.preventDefault()

      targetElement.removeClass('is-active')
    })
  }

  async appendChengelogs(targetElement, element) {
    const {data:{allChangelogs}} = await fetchData()
    const lastTimestamp = allChangelogs && allChangelogs[0]._publishedAt
    const localTimeStamp = localStorage.getItem(`last-timestamp-${element}`)
    const lastDate = new Date(lastTimestamp)
    const lastLocalDate = new Date(localTimeStamp)
    const content = this.changelogContent(allChangelogs, lastLocalDate)

    targetElement.append(content)

    if (lastLocalDate < lastDate) {
      targetElement.addClass('is-active')
    }

    localStorage.setItem(`last-timestamp-${element}`, lastTimestamp)
  }

  changelogContent(allChangelogs, lastLocalDate) {
    let content = ''

    for(const [index, element] of allChangelogs.entries()) {
      const tags = element.tags
      const dateElement = new Date(element._publishedAt)
      let tagElement =  ''
      let isActive = ''

      if(lastLocalDate < dateElement) {
        isActive = 'is-active'
      }

      tags.forEach(e => {
        tagElement += `
          <div
           class="badge--changelog"
           style="background-color: ${e.colour.hex}"
          >
           ${e.name}
          </div>
        `
      })

      content += `
        <div class="actions-nav__menu-item ${isActive}">
          ${tagElement}
          <div class="actions-nav__menu-item__children">
            <strong>
              <a href=${changelogsUrl} target="_blank">
                <span>${element.title}.</span>
                <span class="description">${element.abstract}</span>
              </a>
            </strong>
          </div>
        </div>
        ${ index != (allChangelogs.length - 1) ? '<hr />' : '' }
      `
    }

    return content
  }
}

export default ChangelogToggle
