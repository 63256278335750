import Behaviour from './behaviour'
import debug from '../debug.js.erb'

require('pickadate/lib/picker.time')
require('pickadate/lib/themes/default.css')
require('pickadate/lib/themes/default.time.css')

class TimePicker extends Behaviour {
  init() {
    debug('Init Time Picker behaviour')

    return this.$dom.pickatime({format: 'HH:i', interval: 15})
  }
}

export default TimePicker
