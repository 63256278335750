const development = false

const debug = function(...args) {
  if (!window.console) {
    return
  }

  const debug = localStorage.getItem('debug')

  if (debug && debug !== '1') {
    return
  }

  if (debug === '1' || development) {
    console.log(...args)
  }
}

const log = (area, ...args) => {
  const logAreas = (localStorage.getItem('logAreas') || '').split(',')
  const shouldLog = logAreas.includes(area) || logAreas.includes('*')

  if (shouldLog) {
    console.log(...args)
  }
}

export default debug
export {log}
