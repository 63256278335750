import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class AbsenceKindToggle extends Behaviour {
  init() {
    debug('Init AbsenceKindToggle behaviour')

    this.$dom.ready(_e => {
      return this.toggle_permits()
    })

    return this.$dom.change(e => {
      e.preventDefault()

      return this.toggle_permits()
    })
  }

  toggle_permits() {
    const kind = $('.absence-kind').val()

    if (kind != 'permits') {
      return this.hide_permits()
    } else {
      return this.show_permits()
    }
  }

  hide_permits() {
    $('[role="time-box"]').hide()
    return $('[role="to-box"]').show()
  }

  show_permits() {
    $('[role="time-box"]').show()
    return $('[role="to-box"]').hide()
  }
}

export default AbsenceKindToggle
