import Behaviour from "./behaviour";
import debug from "../debug.js.erb";

class PriorityToggle extends Behaviour {
  init() {
    debug("Init PriorityToggle behaviour");

    this.execute(this.$dom);

    return this.$dom.click((e) => {
      return this.execute(e.currentTarget);
    });
  }

  execute(target) {
    if ($(target).prop("checked")) {
      $('[data-role="PriorityToggledDefaults"]').prop("disabled", true);

      $('[data-role="PriorityToggled"]')
        .find("select")
        .prop("disabled", false);
      return $('[data-role="PriorityToggled"]').show();
    } else {
      $('[data-role="PriorityToggledDefaults"]').prop("disabled", false);

      return $('[data-role="PriorityToggled"]').each(function () {
        $(this).hide();
        const default_val = $(this).data("default");
        const sel = $(this).find("select");

        sel.val(default_val).change();
        sel.prop("opacity", 0);
      });
    }
  }
}

export default PriorityToggle;
