require('fullcalendar/dist/locale/it')

import Behaviour from './behaviour'
import debug from '../debug.js.erb'

const customerReminderPath = 'customer_area/customer_reminders/'

class FullCalendar extends Behaviour {
  init() {
    debug('Init FullCalendar behaviour')

    const that = this

    const eventsApiUrl = this.data('events_api_url')
    const remindersApiUrl = this.data('reminders_api_url')
    const eventSources = [
      {url: eventsApiUrl},
      {url: remindersApiUrl},
      {url: '/api/v1/absences'}
    ]

    $('#calendar').fullCalendar({
      height: 'auto',
      eventSources,
      eventClick(calEvent, _jsEvent, _view) {
        if (calEvent.type === 'intervention') {
          return that.renderInterventionModal(calEvent.id)
        } else if (calEvent.type === 'reminder') {
          return location.pathname = 'reminders/' + calEvent.id
        } else if (calEvent.type === 'customer_reminder') {
          return location.pathname = customerReminderPath + calEvent.id
        } else if (calEvent.type === 'absence') {
          return that.renderAbsenceModal(calEvent.id)
        }
      },
      eventOrder: 'time',
      locale: 'it',
      header: {
        left: 'today prev next',
        center: 'title',
        right: 'agendaDay, agendaWeek, month'
      },
      footer: {
        left: 'today prev next',
        center: 'title',
        right: 'agendaDay, agendaWeek, month'
      },
      dayClick(date, jsEvent, view) {
        if ((view.name === 'month') || (view.name === 'agendaWeek')) {
          $('#calendar').fullCalendar('changeView', 'agendaDay')
          return $('#calendar').fullCalendar('gotoDate', date)
        }
      },
      eventRender(event, element, view) {
        let title = element.find('.fc-title, .fc-list-item-title')
        title.html(event.title.replace(/\n/g, "<br/>"))
      },
      eventAfterAllRender(view) {
        if (view.name === "month") {
          let currentDate = moment()
          let currentMonth = currentDate.month()
          let viewMonth = view.intervalStart.month()
          let formattedCurrentDate = currentDate.format("YYYY-MM-DD")
          let target = $("td[data-date=" + formattedCurrentDate + "].fc-day-top")

          if (target.length > 0 && (currentMonth === viewMonth)) {
            let position = target.offset().top
            let fixedPosition = position - $('.canvas__main__actions').outerHeight() - 10

            $('html, body').animate({scrollTop: fixedPosition}, 1000)
          }
        }
      }
    })

    return $('.js-showAll').text('Mostra miei')
  }

  renderInterventionModal(interventionId) {
    debug(`renderInterventionModal interventionId:${interventionId}`)
    const modal = $('[data-role="dialog-intervention"]')
    return this.requestInterventionView(interventionId).then(response => {
      debug(response)
      debug(modal.find('[data-role="dialog-body"]'))
      return modal.find('[data-role="dialog-body"]').html(response)
    }).then(() => {
      return modal.toggleClass('is-active')
    })
  }

  renderAbsenceModal(absenceId) {
    debug(`renderAbsenceModal absenceId:${absenceId}`)
    const modal = $('[data-role="dialog-intervention"]')
    return this.requestAbsenceView(absenceId).then(response => {
      debug(response)
      debug(modal.find('[data-role="dialog-body"]'))
      return modal.find('[data-role="dialog-body"]').html(response)
    }).then(() => {
      return modal.toggleClass('is-active')
    })
  }

  requestInterventionView(interventionId) {
    debug(`requestInterventionView interventionId:${interventionId}`)
    const interventionType = $('#calendar').data('intervention_type') + 's'
    let interventionUrl = null
    if (interventionType === 'customer_interventions') {
      interventionUrl = 'customer_area/customer_interventions'
    } else {
      interventionUrl = 'interventions'
    }
    return $.ajax({
      type: 'get',
      url: `/${interventionUrl}/${interventionId}/calendar_preview.js`,
      dataType: 'html'
    })
  }

  requestAbsenceView(absenceId) {
    debug(`requestAbsenceView absenceId:${absenceId}`)
    return $.ajax({
      type: 'get',
      url: `/absences/${absenceId}/calendar_preview.js`,
      dataType: 'html'
    })
  }
}

export default FullCalendar
