import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class AccordionToggle extends Behaviour {
  init() {
    debug('Init AccordionToggle behaviour')

    return this.$dom.click(e => {
      e.preventDefault()

      const selectorToToggle = this.data('element')
      const elementToToggle = $(selectorToToggle)
      const parentToToggle = $(e.target)

      elementToToggle.toggleClass('is-active')
      parentToToggle.toggleClass('is-active')
    })
  }
}

export default AccordionToggle
