/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/
              docs/suggestions.md
 */

import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class ModalToggle extends Behaviour {

  init() {
    debug('Init ModalToggle behaviour')

    return this.$dom.click(e => {
      e.preventDefault()
      const selectorToToggle = this.data('element')
      const elementToToggle = $(selectorToToggle)
      elementToToggle.toggleClass('is-active')

      // I need to init the Signature behaviour only when the modal is
      // open
      elementToToggle.find('[data-role="Signature"]')
        .attr('data-behaviour', 'Signature')

      return $(document).trigger('reload-behaviours')
    })
  }
}
export default ModalToggle
