import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class SidebarToggle extends Behaviour {
  init() {
    debug('Init SidebarToggle behaviour')

    return this.$dom.click(e => {
      e.preventDefault()
      $('.canvas').toggleClass('is-shifted')
    })
  }
}

export default SidebarToggle
