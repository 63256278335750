import Behaviour from './behaviour'
import debug from '../debug.js.erb'
import Highcharts from 'highcharts'

class ProfitsDeductionTimeline extends Behaviour {
  init() {
    debug('Init profits deduction timeline charts')

    const data = this.data('values')
    return this.drawChart(data.series)
  }

  drawChart(series) {
    return Highcharts.chart('profits_deduction_timeline', {
      title: {text: 'Totale'},
      xAxis: {
        type: 'datetime',
        tickmarkPlacement: 'on',
        dateTimeLabelFormats: {day: '%Y %b'},
        title: {text: 'Date'},
        labels: {
          rotation: -45,
          formatter() { Highcharts.dateFormat('%Y %b', this.value) }
        }
      },
      tooltip: {split: true},
      plotOptions: {series: {lineWidth: 1.5, marker: {radius: 2},}},
      series
    })
  }
}

export default ProfitsDeductionTimeline
