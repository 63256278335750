import Behaviour from './behaviour'

class PaperProductPlaceholder extends Behaviour {
  init() {
    const placeholder_behaviour = this
    this.$product = $(this.$dom.find('[data-role="product"]'))
    this.$quantity = $(this.$dom.find('[data-role="quantity"]'))

    this.$add_product = $(this.$dom.find('[data-role="add_product"]'))

    this.$add_product.on('click', function(event) {
      event.preventDefault()

      if ((self.$product.val() !== '') && (self.$quantity.val() !== '')) {
        return placeholder_behaviour.add_product_callback()
      }
    })

    var self = this

    return this.$dom.find('[data-role="destroy_product_link"]')
      .bind('click', event => self.bind_destroy(event))
  }

  add_product_callback() {
    const random_element_id = this.element_id()
    const filled_template = this.fill_template(random_element_id)

    document.querySelector('#product-grid').prepend(
      document.importNode(filled_template, true)
    )

    const self = this

    this.$dom.find(`#${random_element_id}`)
      .bind('click', event => self.bind_destroy(event))

    $('[data-role="product"]').val(0)
    $('[data-role="product"]').select2().trigger('change')
    $('[data-role="product"]').select2({width: '100%'})
    return $('[data-role="quantity"]').val('')
  }

  bind_destroy(event) {
    event.preventDefault()

    const parent_element = event.target.closest('tr')
    const element = parent_element.querySelector('[id$="__destroy"]')

    element.setAttribute('value', '1')
    return parent_element.style.display = 'none'
  }

  element_id() {
    return 'destroy_link_' +
      this.$product.val() +
      '_' +
      this.$quantity.val() +
      this.random_id()
  }

  random_id() {
    return Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
  }

  product_name() {
    return this.$dom.find('[data-role="product"] option:selected').text()
  }

  filtered_product_name() {
    return this.product_name().replace(/\(.*?\)/, '')
  }

  unit() {
    return this.product_name().match(/\((.*?)\)/)[0]
  }

  count_new_items() {
    return $('[data-role="hidden_id_field_placeholder"]').length + 1
  }

  fill_template(random_id) {
    const placeholder = document.querySelector('template').content

    const hidden_id = placeholder.querySelector(
      '[data-role="hidden_id_field_placeholder"]'
    )
    const hidden_quantity = placeholder.querySelector(
      '[data-role="hidden_quantity_field_placeholder"]'
    )
    const hidden_destroy = placeholder.querySelector(
      '[data-role="hidden_destroy_field_placeholder"]'
    )
    const quantity_field = placeholder.querySelector(
      '[data-role="quantity-placeholder"]'
    )
    const name_field = placeholder.querySelector(
      '[role="name-placeholder"]'
    )
    const destroy_product_link = placeholder.querySelector(
      '[data-role="destroy_product_link"]'
    )

    const current_id = hidden_id.getAttribute('id').match(/\d+/)[0]

    const new_hide_id = hidden_id.getAttribute('id')
          .replace(current_id, this.count_new_items())
    const new_hide_name = hidden_id.getAttribute('name')
          .replace(current_id, this.count_new_items())
    hidden_id.setAttribute('value', this.$product.val())
    hidden_id.setAttribute('id', new_hide_id)
    hidden_id.setAttribute('name', new_hide_name)

    const new_quantity_id = hidden_quantity.getAttribute('id')
          .replace(current_id, this.count_new_items())
    const new_quantity_name = hidden_quantity.getAttribute('name')
          .replace(current_id, this.count_new_items())
    hidden_quantity.setAttribute('value', this.$quantity.val())
    hidden_quantity.setAttribute('id', new_quantity_id)
    hidden_quantity.setAttribute('name', new_quantity_name)

    const new_hidden_destroy_id = hidden_destroy.getAttribute('id')
          .replace(current_id, this.count_new_items())
    hidden_destroy.setAttribute('id', new_hidden_destroy_id)

    const new_hidden_destroy_name = hidden_destroy.getAttribute('name')
          .replace(current_id, this.count_new_items())
    hidden_destroy.setAttribute('name', new_hidden_destroy_name)

    quantity_field.textContent = this.$quantity.val() + ' ' + this.unit()
    name_field.textContent = this.filtered_product_name()

    destroy_product_link.setAttribute('id', random_id)

    return placeholder
  }
}

export default PaperProductPlaceholder
