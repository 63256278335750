/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/
              docs/suggestions.md
 */

import {Sortable} from 'sortablejs'
import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class DragSort extends Behaviour {
  init() {
    debug('Init DragSort behaviour')
    this.initPlugin()

    //# Assign order to new elements added after plugin initialization
    // i.e. Cocoon nested fields creating a MutationObserver instance

    const observer = new MutationObserver(mutations => {
      return mutations.forEach(_mutation => {
        return this.calculateOrder()
      })
    })
    // configuration of the observer:
    const config = {childList: true, subtree: true}

    // pass in the target node, as well as the observer options
    return observer.observe(this.dom, config)
  }

  initPlugin() {
    const sortable = new Sortable(this.dom, {
      handle: '.js-handle',
      dataIdAttr: 'position',
      onSort: _e => {
        return this.calculateOrder()
      }
    })

    return sortable
  }

  calculateOrder() {
    return (() => {
      const result = []
      const iterable = this.$dom.find('li')
      for (let i = 0; i < iterable.length; i++) {
        const li = iterable[i]
        $(li).find('input[name$="[position]"]').val(i)
        result.push($(li).find('input[name$="[position]"]'))
      }
      return result
    })()
  }
}

export default DragSort
