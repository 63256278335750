import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class CommissionToggle extends Behaviour {
  init() {
    debug('Init CommissionToggle behaviour')

    this.execute(this.$dom)

    return this.$dom.click(e => {
      return this.execute(e.currentTarget)
    })
  }

  execute(target) {
    if ($(target).prop('checked')) {
      $('[data-role="CommissionToggledDefaults"]')
        .prop('disabled', true)

      $('[data-role="CommissionToggled"]').find('select')
        .prop('disabled', false)
      return $('[data-role="CommissionToggled"]').show()

    } else {
      $('[data-role="CommissionToggledDefaults"]')
        .prop('disabled', false)

      return $('[data-role="CommissionToggled"]').each(function() {
        $(this).hide()
        const default_val = $(this).data('default')
        const sel = $(this).find('select')

        sel.val(default_val).change()
        sel.prop('opacity', 0)
      })
    }
  }
}

export default CommissionToggle
