import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class FullScreenToggle extends Behaviour {
  init() {
    debug('Init FullScreenToggle behaviour')

    return this.$dom.click(e => {
      e.preventDefault()
      return this.launchFullscreen(document.documentElement)
    })
  }

  launchFullscreen(element) {
    if (!document.fullscreen) {
      return element.requestFullscreen()
    } else {
      return document.exitFullscreen()
    }
  }
}

export default FullScreenToggle
