/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/
              docs/suggestions.md
 */

import {log} from '../debug.js.erb'

class Behaviour {
  constructor(dom, options = {}) {
    this.dom = dom
    this.options = options
    this.$dom = $(this.dom)
    const className = this.getClassName()
    log(className, `Behaviour constructor for ${className} on`, this.dom)
    if (!this.$dom.data(className)) {
      log(className, `${className} not initialized yet...`)
      this.$dom.data(className, true)
      this.init()
    } else {
      log(className, `${className} initialized...`)
    }
  }

  data(attr, def = null) {
    return this.options[attr] || this.$dom.data(attr) || def
  }

  domData(attr) {
    return $(this.data(attr))
  }

  getClassName() {
    return this.constructor.name
  }

  init() {}
}

export default Behaviour
