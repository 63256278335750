/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/
                      master/docs/suggestions.md
 */

import Behaviour from './behaviour'
import {log} from '../debug.js.erb'

require('pickadate/lib/picker.date')
require('pickadate/lib/themes/default.css')
require('pickadate/lib/themes/default.date.css')

class DatePicker extends Behaviour {
  init() {
    log('DatePicker', 'DatePicker.init')

    const epoch = Date.parse('1/1/2016')
    const minDate = new Date(epoch)

    return this.$dom.pickadate({
      monthsFull: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
      ],
      weekdaysShort: [
        'Dom',
        'Lun',
        'Mar',
        'Mer',
        'Gio',
        'Ven',
        'Sab'
      ],
      firstDay: 1,
      today: 'Oggi',
      clear: 'Rimuovi',
      close: 'Chiudi',
      labelMonthNext: 'Mese successivo',
      labelMonthPrev: 'Mese precedente',
      labelMonthSelect: 'Seleziona un mese',
      labelYearSelect: 'Seleziona un anno',
      format: 'dd/mm/yyyy',
      editable: false,
      min: minDate,
      selectYears: true,
      selectMonths: true
    })
  }
}

export default DatePicker
