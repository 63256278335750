/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any
// other files present in this directory. You're encouraged to place
// your actual application logic in a relevant structure within
// app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %>
// to the appropriate layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output
// folder and reference them with the image_pack_tag helper in views
// (e.g <%= image_pack_tag 'rails.png' %>) or the `imagePath` JavaScript
// helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import debug from '../src/debug.js.erb'
import Rails from '@rails/ujs'
import 'cocoon-js'
import 'fullcalendar'
import 'fullcalendar/dist/fullcalendar.css'
import 'select2'
import initBehaviours from '../src/init_behaviours'

debug('Hello World from Webpacker')

$(function () {
  debug('Hello World from jQuery')
})

document.addEventListener('DOMContentLoaded', () => {
  debug('DOMContentLoaded')
  Rails.start()
  initBehaviours()
})

$(document).on('cocoon:after-insert', function() {
  debug('cocoon:after-insert event triggered')
  return initBehaviours()
})

// To trigger: $(document).trigger('reload-behaviours');
$(document).on('reload-behaviours', function() {
  debug('reload-behaviours event triggered')
  return initBehaviours()
})
