import debug, { log } from "./debug.js.erb";

import ResponsibleToggle from "./behaviours/responsible_toggle";
import CommissionToggle from "./behaviours/commission_toggle";
import PriorityToggle from "./behaviours/priority_toggle";
import ModalToggle from "./behaviours/modal_toggle";
import AccordionToggle from "./behaviours/accordion_toggle";
import FullScreenToggle from "./behaviours/full_screen_toggle";
import SidebarToggle from "./behaviours/sidebar_toggle";
import SearchToggle from "./behaviours/search_toggle";
import DownloadToggle from "./behaviours/download_toggle";
import MovementsKindToggle from "./behaviours/movements_kind_toggle";
import AbsenceKindToggle from "./behaviours/absence_kind_toggle";
import CheckboxHidesElement from "./behaviours/checkbox_hides_element";
import FlashClose from "./behaviours/flash_close";
import FullCalendar from "./behaviours/full_calendar";
import Select from "./behaviours/select";
import SelectIcon from "./behaviours/select_icon";
import SetLocation from "./behaviours/set_location";
import Signature from "./behaviours/signature";
import TimePicker from "./behaviours/time_picker";
import ProfitsDeductionCake from "./behaviours/profits_deduction_cake";
import ProfitsDeductionTimeline from "./behaviours/profits_deduction_timeline";
import DragSort from "./behaviours/drag_sort";
import DatePicker from "./behaviours/date_picker";
import PaperProductPlaceholder from "./behaviours/paper_product_placeholder";
import Uploader from "./behaviours/uploader";
import ChangelogToggle from "./behaviours/changelog_toggle";

window.behaviours = {
  ResponsibleToggle,
  CommissionToggle,
  PriorityToggle,
  AccordionToggle,
  ModalToggle,
  FullScreenToggle,
  SidebarToggle,
  SearchToggle,
  DownloadToggle,
  MovementsKindToggle,
  AbsenceKindToggle,
  CheckboxHidesElement,
  FlashClose,
  FullCalendar,
  Select,
  SelectIcon,
  SetLocation,
  Signature,
  TimePicker,
  ProfitsDeductionCake,
  ProfitsDeductionTimeline,
  DragSort,
  DatePicker,
  PaperProductPlaceholder,
  Uploader,
  ChangelogToggle,
};

const initBehaviours = () => {
  debug("initBehaviours");

  return $("[data-behaviour]").each(function () {
    debug("Initializing behaviours on element:", this);

    const elementBehaviours = $(this).data("behaviour").split(/\s+/);
    debug("element behaviours: ", elementBehaviours);

    const results = elementBehaviours.map((name) => {
      log(name, `Initializing behaviour ${name}`);
      const behaviour = window.behaviours[name];
      if (!behaviour) {
        console.error("Behaviour not found:", name);
        return `Class ${name} not found`;
      }

      new behaviour(this);

      log(name, `Behaviour '${name}' activated`);
      return `Behaviour '${name}' activated`;
    });

    return results;
  });
};

export default initBehaviours;
