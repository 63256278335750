import Behaviour from './behaviour'
import {log} from '../debug.js.erb'

require('select2/dist/css/select2.css')

function icons(state) {
  if (!state.id) {
    return state.text || '\u200B'
  }

  var stateIcon = $(
    `<span>
       <svg class="small icon--blue icon-svg--${state.id}" role="icon">
         <use xlink:href="#icons-${state.id}"></use>
       </svg>
       ${state.text != state.id ? state.text : ""}
     </span>`
  )

  return stateIcon
}

class SelectIcon extends Behaviour {
  init() {
    log('SelectIcon', 'Init Select behaviour')

    const options = {
      width: '100%',
      templateResult: icons,
      templateSelection: icons
    }

    return this.$dom.select2(options)
  }
}

export default SelectIcon
