import Behaviour from './behaviour'
import debug from '../debug.js.erb'
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/
              docs/suggestions.md
 */

class ResponsibleToggle extends Behaviour {

  init() {
    debug('Init ResponsibleToggle behaviour')

    this.execute(this.$dom)
    return this.$dom.click(e => {
      return this.execute(e.currentTarget)
    })
  }

  execute(target) {
    if ($(target).prop('checked')) {
      $('[data-role="ResponsibleToggledDefaults"]')
        .prop('disabled', true)

      $('[data-role="ResponsibleToggled"]').find('select')
        .prop('disabled', false)
      return $('[data-role="ResponsibleToggled"]').show()

    } else {
      $('[data-role="ResponsibleToggledDefaults"]')
        .prop('disabled', false)

      return $('[data-role="ResponsibleToggled"]').each(function() {
        $(this).hide()
        const default_val = $(this).data('default')
        const sel = $(this).find('select')
        sel.prop('disabled', true)
        return sel.val(default_val)
      })
    }
  }
}

export default ResponsibleToggle
