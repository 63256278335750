import Behaviour from './behaviour'
import debug from '../debug.js.erb'

class MovementsKindToggle extends Behaviour {
  init() {
    debug('Init MovementsKindToggle behaviour xxx')

    this.$dom.ready(_e => {
      return this.toggle_permits()
    })

    return this.$dom.change(e => {
      e.preventDefault()
      return this.toggle_permits()
    })
  }

  toggle_permits() {
    const sub_kind = $('#movement_sub_kind').val()
    const kind = $('#movement_kind').val()

    if (sub_kind !== 'external_labour') {
      this.hide_cost()
    } else {
      this.show_cost()
    }

    if (kind !== 'integration') {
      this.show_cancellation()
    } else {
      this.hide_cancellation()
    }
  }

  hide_cost() {
    $('[role="external_work_hours"]').hide()
    $('[role="freezed_hourly_cost"]').hide()

    return $('[role="amount"]').show()
  }

  show_cost() {
    $('[role="external_work_hours"]').show()
    $('[role="freezed_hourly_cost"]').show()

    return $('[role="amount"]').hide()
  }

  show_cancellation() {
    const sub_kind_select = $('#movement_sub_kind')
    const option = $('#movement_sub_kind option[value="cancellation"]')
    const new_option = new Option('Storno', 'cancellation')

    if (option.length === 0) {
      return sub_kind_select.append(new_option)
    }
  }

  hide_cancellation() {
    return $('#movement_sub_kind option[value="cancellation"]').remove()
  }
}

export default MovementsKindToggle
