import Behaviour from './behaviour'
import debug from '../debug.js.erb'
import Highcharts from 'highcharts'

class ProfitsDeductionCake extends Behaviour {
  init() {
    debug('Init profits deduction cake charts')

    const data = this.data('values')
    return this.drawChart(data)
  }

  drawChart(data) {
    const pointFormat = '<b>{point.name}</b>: <b>{point.percentage:.1f}%</b>'

    return Highcharts.chart('profits_deduction_cake', {
      chart: {type: 'pie'},
      title: {text: 'Totale dei profitti'},
      tooltip: {pointFormat: pointFormat},
      colors: ['#bac22d','#058DC7','#FF9655'],
      series: [data]
    })
  }
}

export default ProfitsDeductionCake
